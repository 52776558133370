































import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    order: {}
  },
  setup() {
    const logo = computed(() => require("@/assets/img/visa_master_small.png"));

    return { logo };
  }
});
